<template>
    <div class="modulo-email-page">
        <Breadcrumb titulo="CAMPANHAS" :items="item" backgroundColor="#259cd3"/>

        <div class="modulo-email-page-container-cartoes">
            <div class="modulo-email-page-cartoes" 
                v-for="cartao in cartoes" 
                :key="cartao.id"
                v-if="cartao.canal === null || integracoesHabilitadasEmpresa.includes(cartao.canal)"
            >

                <router-link :to="cartao.link" class="modulos-email-page-router-link">
                    <cartao-dinamico 
                        :titulo="cartao.titulo" 
                        :subtitulo="cartao.subtitulo" 
                        :classe="cartao.classe" 
                        :cor="cartao.cor" 
                        :iconBootstrap="cartao.icon_bootstrap"
                    />
                </router-link>

            </div>
        </div>

    </div>
</template>

<script>
import Breadcrumb from '@/components/breadcrumb/Breadcrumb.vue'
import CartaoDinamico from '@/components/cartao/CartaoDinamico.vue'
import { mapGetters } from 'vuex'

export default {
    name: 'CampanhasPage',

    components: {
        Breadcrumb,
        CartaoDinamico,
        },

    computed: {
        ...mapGetters({
            usuarioLogado: 'authentication/authDadosUsuario'
        }),
        modulosPermitidos() {
            return this.usuarioLogado.ModulosEmpresa.split(',');
        }
    },

    data: function () {
        return {
            textoPesquisa: '',
            item: [
                {
                    id: '1',
                    classe: 'fa fa-bullhorn',
                    texto: 'Campanhas',
                    link: ''
                },
            ],
            cartoes: [
                {
                    id:'1',
                    titulo:'Campanhas Email',
                    subtitulo:'Configurações de campanhas para E-mail',
                    classe: 'fas fa fa-envelope-o',
                    link: '/campanhas-email-template',
                    cor: '#259cd3',
                    iconBootstrap: 'vazio',
                    canal: '2'
                },
                {
                    id:'2',
                    titulo:'Campanhas SMS',
                    subtitulo:'Configurações de campanhas para SMS',
                    classe: 'far fa-comment-alt',
                    link: '/campanhas-sms',
                    cor: '#259cd3',
                    iconBootstrap: 'vazio',
                    canal: '5'
                },
                {
                    id: '3',
                    titulo: 'WhatsApp Business',
                    subtitulo: 'Configurações de campanhas para WhatsApp Business',
                    classe: 'fa fa-whatsapp',
                    link: '/campanhas-whatsapp',
                    cor: '#259cd3',
                    iconBootstrap: 'vazio',
                    canal: '15'
                },
                {
                    id: '5',
                    titulo: 'Mensagens Padrão',
                    subtitulo: 'Configurações para Messagens Padrões',
                    classe: 'fas fa-caret-square-right',
                    link: '/mensagens-padrao',
                    cor: '#259cd3',
                    iconBootstrap: 'vazio',
                },
                {
                    id: '6',
                    titulo: 'Perfil Campanhas',
                    subtitulo: 'Perfil para as Campanhas',
                    classe: 'fas fa-user-tag',
                    link: '/perfil-campanha',
                    cor: '#259cd3',
                    iconBootstrap: 'vazio',
                },
                {
                    id: '7',
                    titulo: 'Whatsapp Convencional',
                    subtitulo: 'Configurações de campanhas para WhatsApp Convencional',
                    classe: 'fa fa-whatsapp',
                    link: '/campanhas-whatsapp-convencional',
                    cor: '#259cd3',
                    iconBootstrap: 'vazio',
                    canal: '9'
                },
                {
                    id: '8',
                    titulo: 'Discador Automático',
                    subtitulo: 'Configuração de discagem telefônica automática',
                    classe: 'fas fa-fax',
                    link: '/discador-automatico',
					cor: '#FD7E14',
                    iconBootstrap: 'vazio',
                    canal: '7'
                },
            ],
        }
    },
    computed: {
        integracoesHabilitadasEmpresa() {
            const usuarioAutenticado = JSON.parse(localStorage.getItem('login'))
            const empresa = this.$store.getters['empresasLicencas/listaEmpresas']?.find(e => e.Id === usuarioAutenticado?.empresaId);
            return empresa?.IntegracoesHabilitadas?.split(',') ?? []
        }
    },
    mounted() {
        this.$store.dispatch('empresasLicencas/buscaEmpresas');
    }
}
</script>

<style scoped>
.modulo-email-page-container-cartoes {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 28px 12px;
}

.modulo-email-page-cartoes {
    width: 33%;
    padding: 10px;
}

.modulos-email-page-router-link {
    text-decoration: none;
    color: inherit;
}

</style>